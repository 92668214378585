import Toastr from 'toastr';
import MobileDetect from 'mobile-detect';
import 'jquery-ui/ui/widgets/autocomplete';
import { axiosClient } from '../../javascripts/front/api_client';

$(() => {
  const elements = document.querySelectorAll('.dismiss-notification');
  Array.from(elements).forEach((el) => {
    // すでにdismissしていたら、dismiss-notification-(ID)でcookieがセットされているので、あればお知らせを削除
    const notificationId = el.getAttribute('data-notification-id');
    if (document.cookie.indexOf(`dismiss-notification-${notificationId}`) >= 0) {
      el.closest('.header-news').remove();
    } else {
      $(el).closest('.header-news').show();
      el.addEventListener('click', (event) => {
        const targetNotificationId = event.target.getAttribute('data-notification-id');
        document.cookie = `dismiss-notification-${targetNotificationId}=${encodeURIComponent(targetNotificationId)
        };max-age=${60 * 60 * 24 * 30}`; // 1か月のみ保持
      });
    }
  });
  $('.notice-message').each(function () {
    if ($(this).hasClass('alert')) {
      Toastr.error($(this).html().trim());
    } else {
      Toastr.success($(this).html().trim());
    }
  });

  const errors = $('#error_explanation li').map(function () {
    return $(this).html().trim();
  });
  if (errors.length > 0) {
    Toastr.error(errors.get().join('<br />'));
  }

  // 企業名の補完
  $('.search_company_name').each(function (_i, _e) {
    const elCampanyName = $(this);
    const loadingBox = elCampanyName.next();

    elCampanyName.autocomplete({ // eslint-disable-line no-underscore-dangle
      source(request, response) {
        loadingBox.removeClass('hide');
        $.ajax({
          url:      `/review_searches/search_reviewer_company?q[name]=${encodeURIComponent(request.term)}`,
          dataType: 'json',
          success(data) {
            loadingBox.addClass('hide');
            elCampanyName.attr('data-companies', JSON.stringify(data.data));
            response(data.data);
          },
        });
      },
      select(event, ui) {
        event.preventDefault();
        const data = ui.item;
        elCampanyName.val(data.company_name);
      },
      focus(event, ui) {
        event.preventDefault();
        const data = ui.item;
        elCampanyName.val(data.company_name);
      },
      delay:     500,
      minLength: 3,
    }).autocomplete('instance')._renderItem = (ul, item) => $('<li>')
      .append(`<div>${item.company_name}</div>`)
      .appendTo(ul);
  });

  // [TOP/ヘッダ]課題カットカテゴリーメニュー
  $(document).on('click', 'div.large-solution-category-text', (e) => {
    // 選択された大カテの中、小カテのHTMLを取得し差し込む
    $.ajax({ url: $(e.target).attr('data-endpoint'), dataType: 'html' })
      .done((data) => {
        $('.solution-search-right-lists').children().remove();
        $('.solution-search-right-lists').html(data);
      });
    return false;
  });

  // アンカーリンク用スクロール処理
  // モバイル閲覧時のみ特別な動きを追加（category-tabsクラスがヘッダーにくっつきposition-fixedになった場合）
  $('a[href^="#"]').not('.product-g2-review-action a, .view_more_block').click(function () {
    const className = $(this).attr('class');
    const speed = 400;
    const href = $(this).attr('href');
    const headerHeight = $('header').height();
    const target = $(href === '#' || href === '' ? 'html' : href);

    if (className && className.includes('smooth-scroll')) {
      const mobile = new MobileDetect(window.navigator.userAgent);
      const tabsHeight = $('#category-description').height();
      const navHeight = $('#nav-bar').height();
      let position = target.offset().top - headerHeight;
      if (mobile.mobile()) {
        const totalHeight = headerHeight + tabsHeight + navHeight;
        position = target.offset().top - totalHeight;
        if ($('#scroll-tab').hasClass('is-sp-fixed')) {
          position -= tabsHeight;
        }
      }
      $('body,html').animate({ scrollTop: position }, speed, 'swing');
    } else {
      const position = target.offset().top - headerHeight;
      $('body,html').animate({ scrollTop: position }, speed, 'swing');
    }
    return false;
  });
});

// trac your prospects
$(() => {
  const getCookie = (name) => {
    let val = [];
    const cookies = document.cookie;
    const cookiesArray = cookies.split(';');

    cookiesArray.forEach((c) => {
      const cArr = c.split('=');
      if (cArr[0].trim() === name) {
        val = cArr[1]; // eslint-disable-line prefer-destructuring
      }
    });
    return val;
  };

  const key = '_itr_trac_your_prospects';
  const url = new URL(window.location.href);
  if (document.cookie.indexOf(key) === -1) {
    const params = {
      params: {
        url: url.href,
      },
    };
    axiosClient.get('/api/tracking/trac_your_prospects', params)
      .then((response) => {
        const { name } = response.data;
        const { value } = response.data;
        document.cookie = `${name}=${value};max-age=${60 * 60 * 24 * 30};Secure`; // 1か月のみ保持
      });
  } else {
    const cookie = getCookie(key);
    const params = {
      params: {
        itr_track_session_id: cookie,
        url:                  url.href,
      },
    };
    axiosClient.get('/api/tracking/trac_your_prospects', params)
      .then((response) => {
        const { name } = response.data;
        const { value } = response.data;
        document.cookie = `${name}=${value};max-age=${60 * 60 * 24 * 30};Secure`; // 1か月のみ保持
      });
  }
});
