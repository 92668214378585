import Axios from 'axios';
import Rails from '@rails/ujs';

const axiosClient = Axios.create({});

// csrfTokenを設定する
axiosClient.defaults.headers.common['X-CSRF-TOKEN'] = Rails.csrfToken();

axiosClient.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 404) {
      Toastr.error('対象のデータが見つかりませんでした');
    } else if (error.response.status === 500) {
      Toastr.error('サーバー内部でエラーが発生しました');
    }
    return Promise.reject(error);
  },
);

const isString = function (obj) {
  return (typeof obj === 'string' || obj instanceof String);
};

// バリデーションエラーをToasterで表示
const toastrErrorMessage = function (errors) {
  Object.keys(errors).forEach((i) => {
    // name: ['入力してください', '100文字以内で入力してください']等のケース
    if (Array.isArray(errors[i]) && errors[i].every(isString)) {
      Toastr.error(errors[i].join('<br />'));
    // descriptions: [{description: ['入力してください等のケース']} ] 等のhas_many系のケース
    } else if (Array.isArray(errors[i]) && errors[i].every(d => !isString(d))) {
      errors[i].forEach((item) => { toastrErrorMessage(item); });
    // description: {description: ['入力してください等のケース']}  等のhas_one系のケース
    } else {
      toastrErrorMessage(errors[i]);
    }
  });
};

export { toastrErrorMessage, axiosClient };
